import { PropsWithChildren } from "react";
import { useNavigate } from "react-router-dom";

type NavSwitchProps = {
  textLeft: string;
  linkLeft: string;
  textRight: string;
  linkRight: string;
};

const NavSwitch = ({
  textLeft,
  linkLeft,
  textRight,
  linkRight,
}: PropsWithChildren<NavSwitchProps>) => {
  const navigate = useNavigate();

  const switchBtn =
    "text-indigo-200 flex items-center justify-center p-4 text-[1.5rem] font-bold tracking-wide font-display hover:text-white";
  const switchBtnActive =
    "border-b-transparent border-b-6 flex items-center justify-center text-[1.5rem] font-bold !text-white";

  const isShepherd = window.location.pathname.indexOf(linkLeft) !== -1;
  const isVine = window.location.pathname.indexOf(linkRight) !== -1;

  return (
    <div className="gap flex rounded-lg">
      <div className="flex h-full flex-col justify-between">
        <button
          className={switchBtn + (isShepherd ? " " + switchBtnActive : "")}
          onClick={() => navigate(linkLeft)}
        >
          {textLeft}
        </button>
        <div
          className={
            "h-1.5 rounded-t bg-cerulean-500 opacity-0" +
            (isShepherd ? " !opacity-100" : "")
          }
        />
      </div>
      <div className="flex h-full flex-col justify-between">
        <button
          className={switchBtn + (isVine ? " " + switchBtnActive : "")}
          onClick={() => navigate(linkRight)}
        >
          {textRight}
        </button>
        <div
          className={
            "h-1.5 rounded-t bg-cerulean-500 opacity-0" +
            (isVine ? " !opacity-100" : "")
          }
        />
      </div>
    </div>
  );
};

export default NavSwitch;
