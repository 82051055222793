import moment from "moment";
import EditIcon from "../../assets/global-assets/edit-icon.svg";
import Tag from "./Tag";
import { handleSubscriptionEndDate } from "../../lib/HelperFunctions";

interface Account {
  id: string;
  email: string;
  subscriptionType: string;
  subscriptionSource: string;
  accountCreateDate: string;
  subscriptionCreateDate: string;
  status: string;
  tags?: string[];
}

interface CustomerDetailsProps {
  customer: Account | null;
}

const handelmailto = (email: string | null | undefined) => {
  if (email) {
    window.location.href = `mailto:${email}`;
  } else {
    throw Error("The customer email is invalid.");
  }
};

export default function CustomerDetails({ customer }: CustomerDetailsProps) {
  const handleSubType = (subType: string | undefined) => {
    if (subType === "com.truplaygames.annual") {
      return "Annual";
    } else if (subType === "com.truplaygames.monthly") {
      return "Monthly";
    } else {
      return "N/A";
    }
  };

  const handleStatusDisplay = (currentStatus: string | undefined) => {
    let bgColor = "bg-cool-gray-500";
    if (currentStatus === "Expired") {
      bgColor = "bg-raspberry-500";
    } else if (currentStatus === "Active") {
      bgColor = "bg-extra-green";
    } else if (currentStatus) {
      bgColor = "bg-tangerine-500";
    }
    return (
      <div
        className={
          "rounded px-1.5 py-2 leading-none text-white" + " " + bgColor
        }
      >
        {currentStatus ? currentStatus : "N/A"}
      </div>
    );
  };

  return (
    <div className="flex h-full flex-col border border-cool-gray-200 bg-white">
      {/* <div className="flex flex-col gap-4 border-b border-b-cool-gray-200 p-4">
        <h3 className="text-2xl font-bold tracking-wide text-indigo-700">
          {customer?.firstName} {customer?.lastName}
        </h3>
        <div className="flex justify-between">
          <div className="flex-1 font-semibold text-cool-gray-700">
            Customer ID#
          </div>
          <div className="flex-1 font-bold text-cool-gray-900">
            {customer?.id}
          </div>
        </div>
      </div> */}
      <div className="flex flex-col gap-4 border-b border-b-cool-gray-200 p-4">
        <div className="flex items-center justify-between">
          <h5 className="text-lg font-bold text-cool-gray-900">
            Account Details
          </h5>
          {/* TODO: Editing will not be functional for MVP */}
          {/* <div className="flex cursor-pointer items-center justify-center rounded-full bg-cool-gray-500 p-2.5 hover:bg-cool-gray-700">
            <img
              className="h-5 w-5"
              src={EditIcon}
              alt="Back"
              loading="eager"
            />
          </div> */}
        </div>
        <div className="flex flex-col">
          <div className="flex-1 font-semibold text-cool-gray-700">Email</div>
          <div
            className="flex-1 text-cerulean-500 hover:underline"
            onClick={() => handelmailto(customer?.email)}
          >
            {customer?.email}
          </div>
        </div>
        <div className="flex items-center justify-between">
          <div className="flex-1 font-semibold text-cool-gray-700">
            Subscription
          </div>
          <div className="flex flex-1 items-center">
            <span className="font-bold text-cool-gray-900">
              {handleSubType(customer?.subscriptionType)}
            </span>
          </div>
        </div>
        <div className="flex items-center justify-between">
          <div className="flex-1 font-semibold text-cool-gray-700">Account</div>
          <div className="flex flex-1">
            {handleStatusDisplay(customer?.status)}
          </div>
        </div>
      </div>
      {/* TODO: We are not currently pulling in tag data */}
      {/* <div className="flex flex-col gap-4 border-b border-b-cool-gray-200 p-4">
        <div className="flex items-center justify-between">
          <h5 className="text-lg font-bold text-cool-gray-900">Tags</h5> */}
      {/* TODO: Editing will not be functional for MVP */}
      {/* <div className="flex cursor-pointer items-center justify-center rounded-full bg-cool-gray-500 p-2.5 hover:bg-cool-gray-700">
            <img
              className="h-5 w-5"
              src={EditIcon}
              alt="Back"
              loading="eager"
            />
          </div> */}
      {/* </div>
        <div className="flex gap-3">
          {customer?.tags?.map((tag) => <Tag key={tag} text={tag} />)}
        </div>
      </div> */}
      <div className="flex flex-col gap-4 p-4">
        <div className="flex items-center justify-between">
          <div className="flex-1 font-semibold text-cool-gray-700">Source</div>
          <div className="flex-1 font-bold text-cool-gray-900">
            {customer?.subscriptionSource || "N/A"}
          </div>
        </div>
        <div className="flex items-center justify-between">
          <div className="flex-1 font-semibold text-cool-gray-700">
            Start Date
          </div>
          <div className="flex-1 font-bold text-cool-gray-900">
            {customer?.subscriptionCreateDate
              ? moment(customer.subscriptionCreateDate).format("l")
              : "N/A"}
          </div>
        </div>
        <div className="flex items-center justify-between">
          <div className="flex-1 font-semibold text-cool-gray-700">
            {customer?.status === "Trialing" ? "Trial " : ""}End Date
          </div>
          <div className="flex-1 font-bold text-cool-gray-900">
            {handleSubscriptionEndDate(
              customer?.subscriptionType,
              customer?.subscriptionCreateDate,
              customer?.status
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
