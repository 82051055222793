import { LegacyRef, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { Context } from "../../config/UserAuthContext";
import Button from "../../components/CommonUI/Button/Button";

import EmailIcon from "../../assets/global-assets/icon-email.svg";
import PasswordIcon from "../../assets/global-assets/icon-password.svg";
import eyeShowIcon from "../../assets/global-assets/icon-eye-show.svg";
import eyeHideIcon from "../../assets/global-assets/icon-eye-hide.svg";
import GoogleSignIn from "../../assets/global-assets/web_light_rd_SI@4x.png";

import {
  signInWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithPopup,
  User,
} from "firebase/auth";
import { auth } from "../../firebase";

/**
 * Login Page to authenticate users with valid email address on file
 *
 * @param props
 * @constructor
 */
interface LoginProps {
  setAuth: (isAuthenticated: boolean) => void;
  setLoginData: (userData: User) => void;
}

/**
 *
 * @param setAuth
 * @param setLoginData
 * @constructor
 */
const Login = ({ setAuth, setLoginData }: LoginProps) => {
  const navigate = useNavigate();
  const provider = new GoogleAuthProvider();

  const emailInputRef: LegacyRef<HTMLInputElement> | undefined = useRef(null);
  const passwordInputRef: LegacyRef<HTMLInputElement> | undefined =
    useRef(null);

  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const [isButtonActive, setIsButtonActive] = useState<boolean>(false);
  const [isCallingAPI, setIsCallingAPI] = useState<boolean>(false);
  const [isPasswordAutofilled, setIsPasswordAutofilled] =
    useState<boolean>(false);

  const [loginError, setLoginError] = useState<string | null>(null);
  // TODO: Add specificity with which input is erroring
  const [isEmailError, setIsEmailError] = useState<boolean>(false);
  const [isPasswordError, setIsPasswordError] = useState<boolean>(false);

  useEffect(() => {
    if (passwordInputRef.current && passwordInputRef.current.value) {
      setIsPasswordAutofilled(true);
    }
  }, [password]);

  useEffect(() => {
    if (!password.length || !email.length) {
      setIsButtonActive(false);
    } else {
      setIsButtonActive(true);
    }
  }, [email.length, password.length]);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  /**
   * Firebase Auth
   * Authenticate user
   */
  const handleLogIn = () => {
    setIsCallingAPI(true);
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user;
        const accessToken = user.getIdToken();
        Cookies.set(
          Context.AuthContext,
          JSON.stringify({ userID: user.uid, token: accessToken })
        );

        Cookies.set(Context.UserContext, JSON.stringify(user));
        console.log(user);
        setLoginData(user);
        setAuth(true);
        navigate("/");
        setIsCallingAPI(false);
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        setLoginError(errorMessage);
        console.log(errorCode, errorMessage);
        setIsCallingAPI(false);
      });
  };

  const handleGoogleLogIn = () => {
    signInWithPopup(auth, provider)
      .then((result) => {
        // The signed-in user info.
        const user = result.user;
        const accessToken = user.getIdToken();
        Cookies.set(
          Context.AuthContext,
          JSON.stringify({ userID: user.uid, token: accessToken })
        );

        Cookies.set(Context.UserContext, JSON.stringify(user));
        console.log(user);
        setLoginData(user);
        setAuth(true);
        navigate("/");
        setIsCallingAPI(false);
      })
      .catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        setLoginError(errorMessage);
        console.log(errorCode, errorMessage);
        setIsCallingAPI(false);
      });
  };

  return (
    <div className="flex h-screen items-center justify-center">
      <div className="flex min-w-[500px] flex-col items-center gap-6 rounded-lg border border-solid border-white bg-cool-gray-200 px-14 py-6">
        <h4 className="text-2xl font-bold text-indigo-700">Login</h4>
        <div className="flex w-full flex-1 flex-col">
          <div className="flex flex-1 flex-col gap-4">
            <div className="relative flex w-full flex-1 items-center">
              <img
                className="absolute left-2.5 z-[3] w-6"
                src={EmailIcon}
                alt=""
                loading="eager"
              />
              <input
                className={
                  "relative flex w-full rounded border-0 bg-white px-2.5 py-4 text-left indent-8 text-indigo-500 outline-none" +
                  (isEmailError || isEmailError
                    ? "ring-1 ring-raspberry-500"
                    : "")
                }
                ref={emailInputRef}
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                placeholder="Email"
                type="email"
              />
            </div>
            <div className="relative flex w-full items-center">
              <img
                className="absolute left-2.5 z-[3] w-6"
                src={PasswordIcon}
                alt=""
                loading="eager"
              />
              <input
                className={
                  "relative flex w-full rounded border-0 bg-white px-2.5 py-4 text-left indent-8 text-indigo-500 outline-none" +
                  (isPasswordError || isPasswordError ? " tru-input-error" : "")
                }
                ref={passwordInputRef}
                value={password}
                type={showPassword ? "text" : "password"}
                placeholder="Password"
                autoComplete="off"
                onChange={(e) => {
                  setPassword(e.target.value);
                  if (!isPasswordAutofilled && e.target.value) {
                    setIsPasswordAutofilled(true);
                  }
                }}
              />
              <div className="absolute right-2.5 z-[3] w-6 items-center">
                <div
                  className=""
                  role="button"
                  onClick={togglePasswordVisibility}
                >
                  {password ? (
                    showPassword ? (
                      <img
                        src={eyeShowIcon}
                        alt="Show Password"
                        loading="lazy"
                        className="tru-input-eye-icon"
                      />
                    ) : (
                      <img
                        src={eyeHideIcon}
                        alt="Hide Password"
                        loading="lazy"
                        className="tru-input-eye-icon"
                      />
                    )
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
            {loginError && (
              <div className="text-sm text-raspberry-500">{loginError}</div>
            )}
          </div>
        </div>
        <Button
          bgColor="bg-cerulean-500"
          onClick={handleLogIn}
          disabled={!isButtonActive}
          loading={isCallingAPI}
        >
          Login
        </Button>
        <img
          className="w-48 cursor-pointer"
          src={GoogleSignIn}
          onClick={handleGoogleLogIn}
          alt="Sign in with Google"
          loading="eager"
        />
      </div>
    </div>
  );
};

export default Login;
