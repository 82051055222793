import Navbar from "../../components/Navbar/Navbar";
import AccountLookup from "../../components/ShepherdDashboard/AccountLookup";
import NPS from "../../components/ShepherdDashboard/NPS";
import RepeatContactMetrics from "../../components/ShepherdDashboard/RepeatContactMetrics";
import TicketVolumneMetrics from "../../components/ShepherdDashboard/TicketVolumneMetrics";
import TimeToResolutionMetrics from "../../components/ShepherdDashboard/TimeToResolutionMetrics";
import moment from "moment";

export default function ShepherdDashboard() {
  return (
    <>
      <Navbar />
      <div className="flex h-full flex-col gap-4 p-4">
        <div className="flex items-center gap-4">
          <AccountLookup />
          <h4 className="flex-[0.3_1_0%] text-right text-lg font-bold tracking-wide text-cool-gray-700">
            {moment().format("MMMM Do YYYY")}
          </h4>
        </div>
        <div className="flex flex-1 gap-4">
          <div className="flex flex-1 flex-col gap-4">
            <TicketVolumneMetrics />
            <div className="flex flex-[0.4_1_0%] gap-4">
              <TimeToResolutionMetrics />
              <RepeatContactMetrics />
            </div>
          </div>
          <div className="h-full flex-[0.3_1_0%]">
            <NPS />
          </div>
        </div>
      </div>
    </>
  );
}
