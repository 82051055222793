import { useNavigate } from "react-router-dom";
import NavSwitch from "./NavSwitch";
import TruPlayLogo from "../../assets/global-assets/truply-logo.png";
import UserMenu from "./UserMenu";

export default function Navbar() {
  const navigate = useNavigate();

  return (
    <div className="flex w-screen justify-between gap-4 bg-[#001B36]">
      <div
        className="flex cursor-pointer items-center gap-2 p-3  pl-6"
        onClick={() => navigate("/")}
      >
        <img loading="lazy" alt="TruPlay" src={TruPlayLogo} className="w-32" />
        <h2 className="font-display text-[1.5rem] font-bold tracking-wide text-[#A8BDD2]">
          - The Ark
        </h2>
      </div>
      <div className="flex gap-4">
        <NavSwitch
          textLeft="The Shepherd"
          linkLeft="/shepherd"
          textRight="The Vine"
          linkRight="/vine"
        />
        <UserMenu />
      </div>
    </div>
  );
}
