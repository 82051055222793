import AccountLookup from "../ShepherdDashboard/AccountLookup";
import ChallengesTab from "./ChallengesTab";
import CurrencyTab from "./CurrencyTab";
import CustomerDetails from "./CustomerDetails";
import NotesTab from "./NotesTab";
import ProfilesTab from "./ProfilesTab";
import { useCustomerContext } from "../../config/CustomerContext";
import Navbar from "../Navbar/Navbar";
import LeftCaret from "../../assets/global-assets/left-caret.svg";
import Tab from "../CommonUI/Tab";
import CustomerTabs from "./CustomerTabs";
import { useNavigate } from "react-router-dom";

export default function CustomerDashboard() {
  const { customer } = useCustomerContext();
  const navigate = useNavigate();

  return (
    <>
      <Navbar />
      <div className="flex flex-1 flex-col gap-4">
        <div className="flex flex-grow items-center gap-4 p-4 pb-0">
          <div className="flex-[0.3_1_0%]">
            <div
              className="group flex cursor-pointer items-center justify-start gap-4"
              onClick={() => navigate("/shepherd")}
            >
              <div
                className="flex items-center justify-center rounded-full bg-cool-gray-500 p-2.5 group-hover:bg-cool-gray-700"
                role="button"
              >
                <img
                  className="h-5 w-5"
                  src={LeftCaret}
                  alt="Back"
                  loading="eager"
                />
              </div>
              <h3 className="text-lg font-bold text-cool-gray-700 group-hover:text-cool-gray-800">
                Dashboard
              </h3>
            </div>
          </div>
          <AccountLookup />
        </div>
        <div className="flex h-full">
          <div className="flex-[0.3_1_0%]">
            <CustomerDetails customer={customer} />
          </div>
          <div className="flex-1">
            <CustomerTabs>
              <Tab title="Notes">
                <NotesTab />
              </Tab>
              <Tab title="Profiles">
                <ProfilesTab />
              </Tab>
              <Tab title="Challenges">
                <ChallengesTab />
              </Tab>
              <Tab title="Currency">
                <CurrencyTab />
              </Tab>
            </CustomerTabs>
          </div>
        </div>
      </div>
    </>
  );
}
