/* 
  TODO: Leaving filter related logic for our switch to our own API
  Klaviyo's API is limited so we will only search by email for now
  Remove this comment once filters are implemented
*/

import { useState } from "react";
import MagnifyingGlass from "../../assets/global-assets/magnifying-glass.svg";
import Close from "../../assets/global-assets/close.svg";
import AccountListItem from "./AccountListItem";
import { useNavigate } from "react-router-dom";
import { useCustomerContext } from "../../config/CustomerContext";
import Button from "../CommonUI/Button/Button";
import axios from "axios";

interface Account {
  id: string;
  email: string;
  subscriptionType: string;
  subscriptionSource: string;
  accountCreateDate: string;
  subscriptionCreateDate: string;
  status: string;
  tags?: string[];
}

// interface Filters {
//   [key: string]: boolean;
// }

export default function AccountLookup() {
  const { customer, setCustomer } = useCustomerContext();
  const [searchInput, setSearchInput] = useState<string>("");
  const [accountsList, setAccountsList] = useState<Account[]>([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
  const [inputPlaceholder, setInputPlaceholder] = useState<string | null>(null);
  const [accountFetchError, setAccountFetchError] = useState<string | null>(
    null
  );
  const [searchLoading, setSearchLoading] = useState<boolean>(false);
  const [accessToken, setAccessToken] = useState<string | null>(null);

  const navigate = useNavigate();

  // const [filters, setFilters] = useState<Filters>({
  //   email: true,
  //   name: false,
  //   id: false,
  // });
  // const [showFilters, setShowFilters] = useState<boolean>(false);

  const parseCustomerData = (customerData: Account) => {
    const parsedData = { ...customerData };
    parsedData.status = parsedData.status.replace(/([A-Z])/g, " $1").trim();
    return { ...parsedData };
  };

  const handleSearch = () => {
    if (searchInput.length < 3) return;
    setAccountsList([]);
    setSearchLoading(true);
    setIsDropdownOpen(true);
    // if (!searchInput.length) {
    //   setAccountsList([]);
    //   setCustomer(null);
    //   return;
    // }
    const accountsToDisplay: Account[] = [];
    const baseUrl: string | undefined = process.env.REACT_APP_ARK_API_BASE_URL;
    // Just searching by email for now
    const email: string = searchInput;
    // const filterArray: string[] = [];
    // Object.entries(filters).forEach(([filter, apply]) => {
    //   if (apply) {
    //     filterArray.push("equals(" + filter + ',"' + searchInput + '")');
    //   }
    // });
    // const filterString = "filter=" + filterArray.join(",");

    // Bearer token
    // axios
    //   .get(`${baseUrl}/account/${email}`, {
    //     headers: {
    //       "Content-Type": "application/json",
    //       Authorization: `Bearer ${accessToken}`,
    //     },
    //   })
    //   .then((response) => {
    //     setSearchLoading(false);
    //     if (response.status === 200) {
    //       console.log(response.data);
    //       accountsToDisplay.push(response.data);
    //       setAccountsList(accountsToDisplay);
    //     }
    //   })
    //   .catch((error) => {
    //     setSearchLoading(false);
    //     setAccountFetchError(error.message);
    //     console.error(accountFetchError);
    //   });

    // Basic auth
    axios({
      method: "get",
      url: baseUrl + "/account/" + email,
      // auth: {
      //   username: process.env.REACT_APP_TEMP_AUTH_USERNAME || "",
      //   password: process.env.REACT_APP_TEMP_AUTH_PASSWORD || "",
      // },
      // withCredentials: true,
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        setSearchLoading(false);
        if (response.status === 200) {
          console.log(response.data);
          const parsedData = parseCustomerData(response.data);
          accountsToDisplay.push(parsedData);
          setAccountsList(accountsToDisplay);
        }
      })
      .catch((error) => {
        setSearchLoading(false);
        setAccountFetchError(error.message);
        console.error(accountFetchError);
      });
    return;
  };

  // const updateFilters = (filter: string) => {
  //   const currentFilters: Filters = { ...filters };
  //   currentFilters[filter] = !currentFilters[filter];
  //   Object.entries(filter);
  //   setFilters({ ...currentFilters });
  // };

  const clearSearch = () => {
    setSearchInput("");
    setAccountsList([]);
    setIsDropdownOpen(false);
  };

  const selectAccount = (account: Account) => {
    setCustomer(account);
    setIsDropdownOpen(false);
    navigate("/shepherd/customer");
  };

  // useEffect(() => {
  //   if (customer) {
  //     setInputPlaceholder(`${customer.email}`);
  //   }
  // }, [customer]);

  // const fillSearch = () => {
  //   if (customer && inputPlaceholder) {
  //     setSearchInput(customer.email);
  //   }
  // };

  return (
    <div className="relative flex-1">
      <div className="relative flex w-full items-center">
        <form
          className="flex flex-1 items-center gap-4"
          onSubmit={(e) => {
            e.preventDefault();
            handleSearch();
          }}
        >
          <div className="relative flex flex-[0.9_1_0%] items-center">
            <img
              className="absolute left-3 cursor-pointer opacity-50 hover:opacity-100"
              src={MagnifyingGlass}
              alt="search"
              loading="eager"
              onClick={() => handleSearch()}
            />
            <input
              id="customer_search"
              className={
                "w-full rounded-lg border border-solid border-cool-gray-400 p-3 indent-6 focus:outline-none focus:ring-2 focus:ring-cerulean-500" +
                // (showFilters
                //   ? " border-b-none ring-cerulean-500 rounded-b-none ring-2"
                //   : "")
                (isDropdownOpen
                  ? " border-b-none rounded-b-none ring-2 ring-cerulean-500"
                  : "")
              }
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
              placeholder={
                inputPlaceholder ? inputPlaceholder : "Enter a customer's email"
              }
              type="text"
              // onFocus={() => fillSearch()}
            />
            <img
              className="close absolute right-3 cursor-pointer opacity-50 hover:opacity-100"
              src={Close}
              alt="close"
              loading="eager"
              onClick={() => clearSearch()}
            />
          </div>
          {/* <input
            className="w-full flex-[0.1_1_0%] rounded-full bg-cerulean-500 py-2 text-white"
            type="submit"
            value="Search"
          /> */}
          <Button
            bgColor="bg-cerulean-500"
            inputSubmit
            inputValue="Search"
            extraStyles="w-full flex-[0.1_1_0%]"
            onClick={() => {}}
          />
        </form>
      </div>
      {/* {showFilters && (
          <div className="border-cool-gray-500 flex gap-2 rounded-se-lg border bg-white p-4">
            <button
              type="button"
              className={
                "rounded-full px-6 py-2" +
                (filters.email
                  ? " bg-cerulean-500 text-white"
                  : " bg-cool-gray-200 text-cool-gray-800")
              }
              onClick={() => updateFilters("email")}
            >
              Email
            </button>
            <button
              type="button"
              className={
                "rounded-full px-6 py-2" +
                (filters.name
                  ? " bg-cerulean-500 text-white"
                  : " bg-cool-gray-200 text-cool-gray-800")
              }
              onClick={() => updateFilters("name")}
            >
              Name
            </button>
            <button
              type="button"
              className={
                "rounded-full px-6 py-2" +
                (filters.id
                  ? " bg-cerulean-500 text-white"
                  : " bg-cool-gray-200 text-cool-gray-800")
              }
              onClick={() => updateFilters("id")}
            >
              TruPlay ID
            </button>
          </div>
        )} */}
      <div className="absolute flex w-full gap-4">
        <div
          className={
            "flex flex-[0.9_1_0%] shrink-0 flex-col rounded-b-xl border border-cool-gray-500 bg-white" +
            (isDropdownOpen ? " visible" : " hidden")
          }
        >
          {accountsList.length && !searchLoading ? (
            <>
              {accountsList.map((accountData) => (
                <AccountListItem
                  key={accountData.id + "-" + accountData.email}
                  account={accountData}
                  active={customer?.email === accountData.email}
                  setActiveAccount={selectAccount}
                />
              ))}
            </>
          ) : (
            <div className="flex cursor-pointer justify-between rounded-b-xl p-4 hover:bg-cool-gray-200">
              {searchLoading ? "Loading..." : "No Records Found"}
            </div>
          )}
        </div>
        <div className="flex-[0.1_1_0%]" />
      </div>
    </div>
  );
}
