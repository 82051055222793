import { createContext, useContext, useState } from "react";

interface Account {
  id: string;
  email: string;
  subscriptionType: string;
  subscriptionSource: string;
  accountCreateDate: string;
  subscriptionCreateDate: string;
  status: string;
  tags?: string[];
}

const emptyCustomer = {
  truPlayId: "",
  firstName: "",
  lastName: "",
  email: "",
  accountType: "",
  subFrom: "",
  subDate: "",
  active: false,
  tags: [],
};

type Setter = (customer: Account | null) => void;

type CustomerContextType = {
  customer: Account | null;
  setCustomer: Setter;
};

const CustomerContext = createContext<CustomerContextType>({
  customer: null,
  setCustomer: function (): void {
    throw new Error("Function not implemented.");
  },
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function CustomerProvider({ children }: any) {
  const [customer, setCustomer] = useState<Account | null>(null);

  return (
    <CustomerContext.Provider
      value={{ customer: customer, setCustomer: setCustomer }}
    >
      {children}
    </CustomerContext.Provider>
  );
}

export function useCustomerContext() {
  return useContext(CustomerContext);
}
