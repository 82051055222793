import moment, { Moment } from "moment";

/* List of possible statuses
 * undefined -> N/A
 * Active
 * Expired
 * -- Below are rare or not seen --
 * 'Apple','Active Grace Period'
 * 'Apple','Revoked'
 * 'Apple','Expired Billing Retry'
 * 'Google','Active Canceled'
 * 'Google','Active Grace Period'
 * 'Google','On Hold'
 * 'Google','Paused'
 * 'Stripe','Trialing'
 * 'Stripe','Canceled'
 * 'Stripe','Past Due'
 * 'Stripe','Incomplete Expired'
 * 'Stripe','Incomplete'
 */

/**
 * Based on the customer's subscription start date, the end date,
 * whether monthly or annual is determined.
 * @param plan Customer subscription type
 * @param startDate Customer subscription start date
 * @param status Customer subscription status
 * @returns Customer's subscription end date
 */
export const handleSubscriptionEndDate = (
  plan: string | undefined,
  startDate: string | undefined,
  status: string | undefined
) => {
  if (!plan || !startDate) {
    return "N/A";
  }
  let subEndDate: Moment | null = null;
  if (status === "Trialing") {
    subEndDate = moment(startDate).add(7, "days");
  } else if (plan === "com.truplaygames.annual") {
    const subRenewalDate = moment({
      year: moment().year(),
      month: moment(startDate).month(),
      day: moment(startDate).date(),
    });
    if (moment() < subRenewalDate) {
      // Renewal Date has not past
      subEndDate = moment(subRenewalDate);
    } else {
      // Renewal Date has past
      if (
        status === "Expired" ||
        status === "Incomplete Expired" ||
        status === "Past Due"
      ) {
        subEndDate = moment(subRenewalDate);
      } else if (
        status === "Revoked" ||
        status === "On Hold" ||
        status === "Paused" ||
        status === "Incomplete" ||
        status === "Active Grace Period"
      ) {
        return "N/A";
      } else {
        subEndDate = moment(subRenewalDate).add(1, "year");
      }
    }
  } else if (plan === "com.truplaygames.monthly") {
    const subEndDay = moment(startDate).date();
    const subEndMonth =
      subEndDay < moment().date()
        ? moment().add(1, "month").month()
        : moment().month();
    subEndDate = moment({
      year: moment().year(),
      month: subEndMonth,
      day: subEndDay,
    });
  }
  return moment(subEndDate?.toString()).format("l") || "N/A";
};
