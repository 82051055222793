import Cookies, { CookieAttributes } from "js-cookie";
import { createContext } from "react";
import * as jose from "jose";
import { signOut } from "firebase/auth";
import { auth } from "../firebase";

export enum Context {
  AuthContext = "AuthContext",
  UserContext = "UserContext",
  Token = "token",
}

export class EmailStatus {
  didSendNewVerificationEmail: string = "";
  isVerified: boolean = false;
  shouldShowEmailVerificationScreen: boolean = false;
}

export class SubscriptionPlan {
  expireTime: string = "";
  isActive: boolean = false;
  platform: string = "";
  productId: string = "";
}

export class Subscription {
  expireTime: string = "";
  isActive: boolean = false;
  sources: SubscriptionPlan[] = [];
}

export class UserAuthContext {
  communicationsOptIn: boolean = false;
  subscription: Subscription = new Subscription();
  token?: string;
  uuId: string = "";
  verifyEmail: EmailStatus = new EmailStatus();

  get HasActiveSubscription(): boolean {
    return this.subscription.isActive;
  }
}

export class AuthContext {
  token?: string;
  userID?: string;
}

export const clearAllCookies = () => {
  Cookies.remove(Context.AuthContext);
  Cookies.remove(Context.UserContext);
  Cookies.remove(Context.Token);
};

export const logOut = () => {
  signOut(auth)
    .then(() => {
      // Sign-out successful.
      console.log("Signed out successfully");
      Cookies.remove(Context.AuthContext);
      Cookies.remove(Context.UserContext);
      Cookies.remove(Context.Token);
    })
    .catch((error) => {
      // An error happened.
      console.error(error.message);
    });
};

export const navigateTo = (destination: string): string => {
  const newUrl: string = "http://" + window.location.host + destination;
  window.location.href = newUrl;
  return newUrl;
};

export const isAuthenticatedWithValidToken = (): boolean => {
  const authContext = Cookies.get(Context.AuthContext);

  if (authContext) {
    const authCtx: UserAuthContext =
      (JSON.parse(authContext) as UserAuthContext) || new UserAuthContext();

    if (authCtx?.token != null) {
      const decodedToken = jose.decodeJwt(authCtx?.token);
      const tokenExpiryDate = new Date((decodedToken.exp as number) * 1000);
      const currentTime = new Date();

      //{"Expire  Time:":tokenExpiryDate,"Current Time:": currentTime};
      return currentTime < tokenExpiryDate;
    }
    return false;
  }
  return false;
};

/**
 * User Auth Context if persisted
 */
export const GetOrCreateSessionAuthContext = (): UserAuthContext => {
  const userContextString: string = Cookies.get("UserContext") || "";

  let userContext: UserAuthContext = new UserAuthContext();
  if (userContextString) {
    userContext = JSON.parse(userContextString);
  }
  return userContext;
};

export const SecureCookieAttributes: CookieAttributes = {
  secure: true,
  sameSite: "strict",
  path: "/",
  // domain: "truplaygames.com",
  // expires: new Date() // Set this to now()+8hrs
};
export const SetAuthTokenAsCookies = (
  userContextString: string,
  email: string
) => {
  let userContext: UserAuthContext = new UserAuthContext();
  if (userContextString) {
    userContext = JSON.parse(userContextString);
  }
  Cookies.set("UserContext", JSON.stringify(userContext));
  Cookies.set(
    "AuthContext",
    JSON.stringify({ userID: userContext.uuId, token: userContext.token })
  );
  Cookies.set("EmailAddress", email);
};

/**
 * Expose user auth info to all downstream components
 */
export const SessionAuthContext = createContext(
  GetOrCreateSessionAuthContext()
);
export default UserAuthContext;
