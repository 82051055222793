import Navbar from "../../components/Navbar/Navbar";

export default function Home() {
  return (
    <>
      <Navbar />
      <div className="flex flex-col items-center justify-center gap-12 p-6">
        <h1>Welcome to the Ark!</h1>
      </div>
    </>
  );
}
