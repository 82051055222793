import React, { useState } from "react";
import TabButton from "../CommonUI/TabButton";

type CustomerTabsProps = {
  children: JSX.Element[];
};

const Tabs: React.FC<CustomerTabsProps> = ({ children }) => {
  const [currentTab, setCurrentTab] = useState<number>(0);

  return (
    <div>
      <div className="flex border-b border-t border-b-cool-gray-200 border-t-cool-gray-200 bg-white">
        {children.map((tab, tabIndex) => (
          <TabButton
            key={tab.props.title}
            title={tab.props.title}
            setTab={setCurrentTab}
            tabIndex={tabIndex}
            active={currentTab === tabIndex}
          />
        ))}
      </div>
      <div>{children[currentTab]}</div>
    </div>
  );
};

export default Tabs;
