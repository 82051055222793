import React from "react";

type TabProps = {
  title: string;
  children: React.ReactNode;
};

const Tab: React.FC<TabProps> = ({ children }): JSX.Element => {
  return <div>{children}</div>;
};

export default Tab;
