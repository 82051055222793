interface TabButtonProps {
  title: string;
  tabIndex: number;
  setTab: (tab: number) => void;
  active: boolean;
}

export default function TabButton({
  title,
  tabIndex,
  setTab,
  active,
}: TabButtonProps) {
  return (
    <div className="flex h-full flex-col justify-between">
      <button
        className={
          "flex items-center justify-center p-4 font-display text-2xl font-bold text-cool-gray-700 hover:text-cerulean-500" +
          (active ? " !text-cerulean-500" : "")
        }
        onClick={() => setTab(tabIndex)}
      >
        {title}
      </button>
      <div
        className={
          "h-1 rounded-t bg-cerulean-500 opacity-0" +
          (active ? " !opacity-100" : "")
        }
      />
    </div>
  );
}
