import Navbar from "../../components/Navbar/Navbar";

export default function VineDashboard() {
  return (
    <>
      <Navbar />
      <div className="flex items-center justify-center p-6">
        <h1>THE VINE</h1>
      </div>
    </>
  );
}
