import SettingsCog from "../../assets/global-assets/settings-cog.svg";
import { useState } from "react";
import { logOut, navigateTo } from "../../config/UserAuthContext";

export default function UserMenu() {
  const [showUserMenu, setShowUserMenu] = useState<boolean>(false);

  const handleUserMenu = () => {
    setShowUserMenu(!showUserMenu);
  };

  return (
    <div className="relative flex h-full">
      <div
        className="flex cursor-pointer items-center p-3 pr-6"
        onClick={handleUserMenu}
      >
        <img loading="eager" alt="" src={SettingsCog} />
      </div>
      {showUserMenu && (
        <div
          className="absolute -bottom-[58px] right-0 flex flex-col items-center rounded-b-xl border border-cool-gray-500 bg-white"
          onClick={() => {
            logOut();
            navigateTo("/login");
          }}
        >
          <div className="flex cursor-pointer items-center whitespace-nowrap p-4 text-left font-bold text-cool-gray-900 hover:rounded-b-xl hover:bg-cool-gray-300">
            Log Out
          </div>
        </div>
      )}
    </div>
  );
}
