import { useContext, useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import "./assets/css/ArkApp.css";
import Home from "./pages/ArkDashboard/ArkDashboard";
import ShepherdDashboard from "./pages/ShepherdDashboard/ShepherdDashboard";
import VineDashboard from "./pages/VineDashboard/VineDashboard";
import CustomerDashboard from "./components/CustomerDashboard/CustomerDashboard";
import { CustomerProvider } from "./config/CustomerContext";
import {
  isAuthenticatedWithValidToken,
  logOut,
  SessionAuthContext,
} from "./config/UserAuthContext";
import Login from "./pages/Login/Login";
import { User } from "firebase/auth";

function App(): JSX.Element {
  const currentUserAuthContext = useContext(SessionAuthContext);
  return (
    <SessionAuthContext.Provider value={currentUserAuthContext}>
      <CustomerProvider>
        <div className="flex h-screen flex-col bg-cool-gray-100">
          <Pages />
        </div>
      </CustomerProvider>
    </SessionAuthContext.Provider>
  );
}

function Pages() {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [loginData, setLoginData] = useState<User | null>(null);

  useEffect(() => {
    const checkAuthentication = async () => {
      const isAuthenticatedNow = isAuthenticatedWithValidToken();
      setIsAuthenticated(isAuthenticatedNow);
    };

    checkAuthentication();

    if (loginData && !isAuthenticated) {
      logOut();
    }
  }, [isAuthenticated, loginData]);

  return (
    <Router>
      {/* Account.Web login method. temporary until SSO correctly implemented */}
      <Routes>
        {isAuthenticated ? (
          <Route path="/login" element={<Navigate to="/" />} />
        ) : (
          <Route
            path="/login"
            element={
              <Login setAuth={setIsAuthenticated} setLoginData={setLoginData} />
            }
          />
        )}
        {isAuthenticated ? (
          <Route path="/" element={<Home />} />
        ) : (
          <Route path="/" element={<Navigate to="/login" />} />
        )}
        {isAuthenticated && (
          <>
            <Route path="/shepherd" element={<ShepherdDashboard />} />
            <Route path="/shepherd/customer" element={<CustomerDashboard />} />
            <Route path="/vine" element={<VineDashboard />} />
          </>
        )}
        <Route path="*" element={<div>404: Not Found</div>} />
      </Routes>
    </Router>
  );
}
export default App;
