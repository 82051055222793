import { PropsWithChildren } from "react";
import { FaSpinner } from "react-icons/fa";
import "./Button.css";

type ButtonProps = {
  bgColor: string; // Tailwind bg color class
  textColor?: string; // Tailwind text color class
  onClick: () => void | null;
  inputSubmit?: boolean; // Button is an input submit for a form
  inputValue?: string; // Input values prop
  disabled?: boolean; // Button is disabled
  extraStyles?: string; // Additional Tailwind Styles
  loading?: boolean; // True if clicking the button results in something loading
};

const btnDefaultClasses =
  "flex cursor-pointer items-center justify-center whitespace-nowrap rounded-full px-6 py-2 text-base font-bold text-white shadow-lg duration-200 w-20 h-10";
const btnInput = "cursor-pointer rounded-full py-2 hover:bg-cerulean-600";
const btnDisabled = "opacity-50";

const Button = ({
  bgColor,
  textColor = "text-white",
  onClick,
  inputSubmit = false,
  inputValue = "Submit",
  disabled = false,
  extraStyles = "",
  loading = false,
  ...props
}: PropsWithChildren<ButtonProps>) => {
  if (inputSubmit) {
    return (
      <input
        className={[bgColor, textColor, btnInput, extraStyles].join(" ")}
        type="submit"
        value={inputValue}
      />
    );
  }

  return (
    <button
      className={
        btnDefaultClasses +
        " " +
        bgColor +
        " " +
        extraStyles +
        (disabled ? " " + btnDisabled : "")
      }
      disabled={disabled}
      onClick={() => onClick()}
    >
      <div>
        {loading ? (
          <FaSpinner className="animate-spin text-xl" />
        ) : (
          props.children
        )}
      </div>
    </button>
  );
};

export default Button;
