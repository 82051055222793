import moment from "moment";
import { handleSubscriptionEndDate } from "../../lib/HelperFunctions";

interface Account {
  id: string;
  email: string;
  subscriptionType: string;
  subscriptionSource: string;
  accountCreateDate: string;
  subscriptionCreateDate: string;
  status: string;
  tags?: string[];
}

interface AccountListItemProps {
  account: Account;
  active?: boolean;
  setActiveAccount: (account: Account) => void;
}

export default function AccountListItem({
  account,
  active,
  setActiveAccount,
}: AccountListItemProps) {
  /**
   * Formats the status of the customer's subscription
   * @param status Customer subscription status
   */
  const handleStatusFormat = (currentStatus: string) => {
    let textColor: string = "text-raspberry-500";
    if (currentStatus === "Expired") {
      textColor = "text-raspberry-500";
    } else if (currentStatus === "Active") {
      textColor = "text-cerulean-500";
    } else {
      textColor = "text-tangerine-400";
    }

    return (
      <div
        className={
          "font-extrabold uppercase" +
          " " +
          textColor +
          " " +
          (active ? " text-white" : "")
        }
      >
        {currentStatus}
      </div>
    );
  };

  return (
    <div
      className={
        "flex cursor-pointer justify-between rounded-b-xl p-4 hover:bg-cool-gray-200" +
        (active ? " bg-gradient-to-r from-cerulean-500 to-truquoise-500" : "")
      }
      onClick={() => setActiveAccount(account)}
    >
      <div className="flex flex-col">
        <div
          className={
            "flex items-center text-cool-gray-900" +
            (active ? " text-white" : "")
          }
        >
          {/* <span className="font-bold">
            {account.firstName} {account.lastName}
            &nbsp;&nbsp;
          </span> */}
          {/* <span className="font-semibold">-&nbsp;&nbsp;{account.email}</span> */}
          <span className="font-semibold">{account.email}</span>
        </div>
        <div
          className={
            "flex items-center font-semibold text-cool-gray-700" +
            (active ? " text-white" : "")
          }
        >
          {moment(account.subscriptionCreateDate).format("l")} •{" "}
          {handleSubscriptionEndDate(
            account.subscriptionType,
            account.subscriptionCreateDate,
            account.status
          )}
        </div>
      </div>
      <div className="flex items-center justify-center">
        {handleStatusFormat(account.status)}
      </div>
    </div>
  );
}
