export default function ChallengesTab() {
  return (
    <div className="flex flex-col gap-4 p-4">
      <div className="flex flex-1 flex-col rounded-lg bg-white p-4 shadow-[0px_4px_8px_0px_#DEE1E5]">
        <h3>Challenges Tab</h3>
        <div>PLACEHOLDER</div>
      </div>
    </div>
  );
}
